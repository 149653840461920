<template>
  <!-- Start Contact -->
  <section class="container pt-4">
    <div class="row align-items-center justify-content-center">
      <div class="col-10 col-md-8">
        <h1 class="col-12 col-xl-8 h2  text-primary">{{ title }}</h1>
        <h2 v-if="subtitle" class="col-12 col-xl-8 h4 regular-400">{{ subtitle }}</h2>
        <p v-if="description" class="col-12 col-xl-8 light-300" v-html="description"></p>
      </div>
    </div>

    <div class="row pb-4 mt-4">
      <!-- Start Contact Form -->
      <div>
        <div class="col-md-12 col-12 mb-3" v-show="emailDemo">
          <button
            type="submit"
            class="btn btn-secondary rounded-pill px-md-5 px-4 py-2 radius-0 text-light light-300"
            @click="switchStrategy"
          >
            <i class="bx bx-rotate-left"></i>
            Using {{ strategies.actual }} to send
          </button>
        </div>

        <!-- https://developers.google.com/web/fundamentals/design-and-ux/input/forms?hl=es#valores_de_los_atributos_name_y_autocomplete_de_entrada_recomendados -->
        <form id="contactForm" ref="formElement" class="contact-form row" method="post" action="#" role="form">
          <input-floating
            v-for="(inputText, index) in inputFloating.typeText"
            :key="index"
            :name="inputText.inputName"
            v-model="inputText.vModel"
            :translation="inputText.translation"
            :col-size="inputText.colSize"
          />

          <div class="col-12">
            <div class="form-floating mb-3">
              <textarea
                v-model="inputFloating.typeArea.message.vModel"
                class="form-control light-300"
                rows="8"
                :name="inputFloating.typeArea.message.inputName"
                form="contactForm"
                placeholder="Mensaje"
                id="floatingtextarea"
              ></textarea>
              <label for="floatingtextarea">{{ inputFloating.typeArea.message.translation }}</label>
            </div>
          </div>
          <!-- End Textarea Message -->

          <div class="col-md-12 col-12 m-auto text-end">
            <button
              type="submit"
              class="btn btn-secondary rounded-pill px-md-5 px-4 py-2 radius-0 text-light light-300 bg-primary"
              @click="sendForm"
            >
              Enviar email
            </button>
          </div>
        </form>
        <div ref="iframeHolder">
          <iframe id="my-response-iframe" ref="iframeElement" name="my-response-iframe" v-show="iframeShow"></iframe>
        </div>
      </div>
      <!-- End Contact Form -->
    </div>
  </section>
</template>
<script>
import InputFloating from '@/components/Contact/InputFloating';
import Swal from 'sweetalert2';

export default {
  name: 'ContactDemo',
  components: { InputFloating },
  data() {
    return {
      inputFloating: {
        typeText: {
          name: this.format('name', '', 'Nombre', 'entry.1376077471'),
          email: this.format('email', '', 'Correo Electrónico', 'emailAddress'),
          phone: this.format('phone', '', 'Teléfono', 'entry.2021664993'),
          subject: this.format('subject', '', 'Asunto', 'entry.1411949283', 'col-12'),
        },
        typeArea: {
          message: this.format('message', '', 'Mensaje', 'entry.848477210'),
        },
      },
      strategies: {
        mailto: 'mailto',
        google: 'google',
        actual: 'google',
      },
      iframeShow: false,
      emailDemo: false,
    };
  },
  methods: {
    /**
     * Values for input-floating
     *
     * @param {string} inputName - The value in name form, important for autocompletion
     * @param {string} vModel - The init value that holds the input data for the user
     * @param {string} translation - The shown text
     * @param {string|null} googleForm - In case of use a contact form of google
     * @param {string|null} colSize - Bootstrap column size
     */
    format(inputName, vModel, translation, googleForm = null, colSize = null) {
      let object = {};
      object.inputName = inputName;
      object.vModel = vModel;
      object.translation = translation;
      object.googleForm = googleForm;
      object.colSize = colSize;
      return object;
    },
    switchStrategy() {
      if (this.strategies.actual === this.strategies.mailto) {
        this.strategies.actual = this.strategies.google;
      } else {
        this.strategies.actual = this.strategies.mailto;
      }
    },
    sendForm(event) {
      event.preventDefault();
      if (this.strategies.actual === this.strategies.mailto) {
        this.sendFormWithMailto();
      } else {
        this.sendFormWithGoogleForms();
      }
    },
    sendFormWithMailto() {
      var EOL = '%0D%0A';
      var messageParsed = this.inputFloating.typeArea.message.vModel.trim().replace(/(?:\r\n|\r|\n)/g, EOL);

      const typeTexts = this.inputFloating.typeText;
      let personalInfoString = '';
      for (let typeTextKey in typeTexts) {
        personalInfoString += typeTexts[typeTextKey].vModel
          ? `${typeTexts[typeTextKey].translation}: ${typeTexts[typeTextKey].vModel}${EOL}`
          : '';
      }

      const subjectForm = this.inputFloating.typeText.subject.vModel;
      const subject = subjectForm ? `subject=${subjectForm}&` : '';

      var url = `mailto:public.contact@hidra-brand.com?${subject}body=
Saludos Hidra-brand${EOL}${EOL}

Mis datos de contacto son los siguientes:${EOL}${EOL}
${personalInfoString}
${EOL + messageParsed}`;

      Swal.fire({
        title: 'Redirección',
        html:
          '<i class="bx bx-rotate-left"></i><div>Te redireccionaremos a tu correo para que nos envies la información del formulario</div>',
      }).then((result) => {
        if (result.isConfirmed) {
          window.open(url);
        }
      });
      url;
    },

    sendFormWithGoogleForms() {
      let refs = this.$refs;

      const form = refs.formElement;
      const iframe = refs.iframeElement;
      const iframeHolder = refs.iframeHolder;
      const iframeClone = iframe.cloneNode(true);

      form.target = 'my-response-iframe';
      form.action =
        'https://docs.google.com/forms/d/e/1FAIpQLSfd5gYspA8I3DMffpsgJHZrUawh7b6vcID4zH02nNtamccm8A/formResponse';
      iframe.name = 'my-response-iframe';

      const typeTexts = this.inputFloating.typeText;
      const typeAreas = this.inputFloating.typeArea;

      _reAssignForm('googleForm');

      form.submit();

      form.target = '';
      form.action = '';
      iframe.remove();
      iframeHolder.appendChild(iframeClone);

      Swal.fire({
        icon: 'success',
        title: 'El mensaje se ha enviado correctamente',
        text: 'Nosotros nos ponemos en contacto contigo',
        // footer: '<a href="/404">Admira el espacio exterior por lo mientras</a>',
      });

      _reAssignForm('inputName');

      /**
       * Switch form names you can use it to fit with google forms then revert it
       *
       * @param {string} attrToAssign - Re-assignation value of name form
       */
      function _reAssignForm(attrToAssign) {
        for (let typeTextKey in typeTexts) {
          let object = typeTexts[typeTextKey];
          form[object.inputName].name = object[attrToAssign];
        }
        form[typeAreas.message.inputName].name = typeAreas.message[attrToAssign];
      }
    },
  },
  props: {
    title: {
      type: String,
      default: 'Create success campaign with us!',
    },
    subtitle: {
      type: String,
      default: 'Elit, sed do eiusmod tempor',
    },
    description: {
      type: String,
      default:
        'Incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices ' +
        'gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Laboris ' +
        'nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit' +
        'in voluptate.',
    },
  },
};
</script>
