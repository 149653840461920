<template>
  <section :class="[$style.component, 'bg-light']" :style="cssProps">
    <div class="container py-4 background-custom col-10 col-md-8">
      <div class="row align-items-center justify-content-between">
        <div :class="['contact-header', colSize]">
          <h1 class="h2 pb-3 text-primary">{{ bannerHero.title }}</h1>
          <h3 class="h4 regular-400">{{ bannerHero.subtitle }}</h3>
          <p class="light-300 text-justify" v-html="bannerHero.description"></p>
        </div>
        <div v-if="bannerHero.banner" class="contact-img col-lg-5 align-items-end">
          <!--          //todo: alt-->
          <img :src="imagePath" alt="banner" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BannerHero',
  props: {
    bannerHero: {
      type: Object,
    },
  },
  computed: {
    imagePath() {
      return require('@a/img/' + this.bannerHero.banner);
    },
    colSize() {
      return this.bannerHero.banner ? 'col-lg-7' : null;
    },
    cssProps() {
      return {
        '--backgroundUrl': 'url(' + this.bannerHero.background + ')',
      };
    },
  },
  methods: {
    /**
     * Values for input-floating
     *
     * @param {string|null} title - The value in name form, important for autocompletion
     * @param {string|null} banner - The init value that holds the input data for the user
     * @param {string|null} background - The init value that holds the input data for the user
     * @param {string|null} subtitle - The shown text
     * @param {string|null} description - In case of use a contact form of google
     */
    Object(title = null, banner = null, background = null, subtitle = null, description = null) {
      const object = {};
      object.title = title || 'Contacto';
      object.banner = banner;
      object.subtitle = subtitle || 'Elit, sed do eiusmod tempor';
      object.background = background;
      object.description =
        description ||
        'Vector illustration is from <a rel="nofollow" href="https://storyset.com/" ' +
          'target="_blank">StorySet</a>. Incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.';
      return object;
    },
  },
};
</script>

<style lang="scss" module>
@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss';


.component :global {
  //background-image: url("/Silver-Square-Pattern--Arvin61r58.svg");
  //background-repeat: repeat;

  background-image: none;


  @include media-breakpoint-up(md) {
    background-image: var(--backgroundUrl);
    background-size: 100%;
  }
}
</style>
