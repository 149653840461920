<template>
  <div :class="[defaultColSize, 'mb-4']">
    <div class="form-floating">
      <input
        @input="$emit('input', $event.target.value)"
        type="text"
        class="form-control form-control-lg light-300"
        :id="'floating' + name"
        :name="name"
        :placeholder="translation || name"
      />
      <label :for="'floating' + name">{{ translation || name }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputFloating',
  props: {
    name: {
      type: String,
      required: true,
    },
    translation: {
      type: String,
    },
    colSize: {
      type: String,
    },
  },
  computed: {
    defaultColSize() {
      return this.colSize || 'col-lg-4 col-md-4';
    },
  },
};
</script>
