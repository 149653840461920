<template>
  <div :class="$style.contact" :style="cssProps">
    <contact-form :title="bannerHero.title" :subtitle="bannerHero.subtitle" :description="bannerHero.description" />
  </div>
</template>
<script>
import BannerHero from '@/components/BannerHero';
import ContactForm from '@/components/Contact/ContactForm';
import 'bootstrap';

export default {
  name: 'Contact',
  components: { ContactForm },
  computed: {
    cssProps() {
      return {
        '--backgroundUrl': 'url(' + this.bannerHero.background + ')',
      };
    },
  },
  data() {
    return {
      bannerHero: BannerHero.methods.Object(
        'Contacto',
        null,
        'https://i.imgur.com/aCdagpI.jpeg',
        '¡Crea un asombroso proyecto con nosotros!',
        'Cuéntanos por medio del formulario, acerca de tu proyecto ' +
          'para poder registrar tu marca o coloca tus datos de contacto de nombre, ' +
          'teléfono y correo.' +
          '<br />' +
          '<br />' +
          'Nosotros nos ponemos en contacto contigo para que ' +
          'nos puedas plantear tus dudas e inquietudes.'
      ),
    };
  },
};


</script>

<style lang="scss" module>
@import 'node_modules/bootstrap/scss/_functions.scss';
@import 'node_modules/bootstrap/scss/_variables.scss';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints.scss';


.contact {
  //background-image: url("/Silver-Square-Pattern--Arvin61r58.svg");
  //background-repeat: repeat;

  background-image: none;

  @include media-breakpoint-up(md) {
    background-image: var(--backgroundUrl);
    background-size: 100%;
  }
}
</style>
